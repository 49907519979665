:global {
  html {
    box-sizing: border-box;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica,
      Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    height: 100%;
  }

  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    font-family: inherit;
  }

  body {
    width: 100%;
    height: 100%;
    min-height: 100%;
    background-color: #fff;
    font-size: 16px;
  }

  #app {
    width: 100%;
    height: 100%;
  }

  #app > div[tabindex='-1'] {
    min-height: 100%;
    height: 100%;
  }

  .bp4-control {
    margin-bottom: 0;
  }
}
