@import "~/styles/common/layout.scss";

.dragPanel {
  @include row(flex-start, center);
  flex-grow: 1;
  flex-shrink: 0;

  transition: all 0.25s ease-out;
  height: 100%;

  cursor: row-resize;
  user-select: none;

  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=")
    center no-repeat;

  &:hover {
    background-color: #f6f6f6;
  }

  &:active {
    background-color: darken(#f6f6f6, 3);
    cursor: grabbing;
  }
}
