.wrapper {
  display: flex;
  width: 38px;
  height: 12px;

  &.disabled {
    opacity: 0.5;
  }

  .item {
    width: 6px;
    height: 12px;
    margin-right: 2px;
    border-radius: 2px;
    background-color: #d1deea;

    &:last-child {
      margin-right: 0;
    }

    &.colored {
      background-color: #2689c1;
    }
  }
}
