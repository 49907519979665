.wrapper {
  display: flex;
  flex-direction: column;
  padding: 5px 0 0 10px;
  height: 100%;

  .tutorialContent {
    overflow: auto;
    padding-right: 17px;
    flex: 1;
  }
}
