.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #eff4f7;
  border-radius: 6px;
  padding: 15px 18px 7px 18px;
  margin-bottom: 11px;

  &:last-child {
    margin-bottom: 0;
  }

  .row {
    $label-width: 87px;
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    .label {
      position: relative;
      min-width: $label-width;
      max-width: $label-width;
      padding-right: 25px;
      text-align: right;
      font-size: 12px;
      line-height: 18px;
      color: #8a969f;
      font-weight: 700;

      &.required {
        &::after {
          position: absolute;
          top: 1px;
          right: 2px;
          content: "﹡";
          font-size: 16px;
          color: rgb(139, 61, 61);
        }
      }
    }

    .inputWrapper {
      min-width: calc(100% - #{$label-width});
      max-width: calc(100% - #{$label-width});
    }
  }

  .deleteButton {
    position: absolute;
    top: -5px;
    right: -7px;
    display: flex;
    justify-content: center;
    width: 18px;
    height: 18px;
    font-size: 14px;
    line-height: 16px;
    border-radius: 18px;
    color: #fff;
    background-color: #e36d52;
    font-weight: 700;
    cursor: pointer;

    &:hover {
      background-color: #c5573f;
    }
  }
}
