.wrapper {
  text-align: center;

  .label {
    margin-bottom: 4px;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #778187;
  }

  .button {
    display: inline-block;
    height: 31px;
    padding: 0 10px 0 36px;
    border-radius: 6px;
    border: 1px solid #b6cedb;
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: 9px;
    font-size: 14px;
    line-height: 31px;
    font-weight: bold;
    background-image: url('/static/assets/images/slack-icon.png');
    background-size: 19px 19px;
    color: #000;
    background-color: #fff;
    box-shadow: 0 0 22px rgba(27, 57, 77, 0.11),
      inset 0 -1px 1px rgba(16, 22, 26, 0.1);
  }

  &:hover {
    text-decoration: none;

    .label {
      color: #444;
    }

    .button {
      background-color: #f7f7f7;
    }
  }
}
