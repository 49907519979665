.shareUrl,
.shareError {
  white-space: pre-wrap;
  word-break: break-all;
  font-family: "Fira Code", monospace;
  font-size: 14px;
}

.copyButton {
  position: relative;
  margin-left: 10px;
  font-size: 14px;
  top: -2px;
}
