@import '~/styles/common/layout.scss';

.panel {
  @include column();
  position: absolute;
  bottom: 0;
  top: 66%;
  width: 100%;
  box-shadow: 0 0 34px rgba(0, 0, 0, 0.16);
  z-index: var(--bottom-panel-z-index);
  flex-shrink: 0;
  z-index: 20;

  background-color: #fff;

  .slackButton {
    position: absolute;
    top: -60px;
    right: 15px;
    z-index: var(--slack-button-z-index);
  }

  .dragPanel {
    @include exact-size(default, 10px);
    @include row(flex-start);

    flex-grow: 1;
  }

  .content {
    flex-grow: 1;
    height: 100%;
    overflow: hidden;
  }
}
