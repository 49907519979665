@import '~@blueprintjs/core/lib/scss/variables.scss';

$pt-button-height: 26px;
$pt-input-height: 26px;
$pt-button-height-large: 30px;
$pt-input-height-large: 30px;
$pt-border-radius: 3px;

$pt-font-family: 'Inter', sans-serif;
$pt-font-family-monospace: 'Fira Code', monospace;

@import '~@blueprintjs/core/src/blueprint.scss';
@import '~@blueprintjs/select/src/blueprint-select.scss';
@import '~@blueprintjs/popover2/lib/css/blueprint-popover2.css';

.bp3-control {
  margin-bottom: 0;
}
