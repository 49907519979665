@import "~/styles/common/layout.scss";

@supports (-webkit-backdrop-filter: blur(1px)) {
  svg {
    height: intrinsic;
  }
}

.wrapper {
  background-color: #fff;
  border-radius: 10px;
  user-select: none;
  box-shadow: 0 0 var(--endpoint-shadow-size) rgba(23, 49, 65, 0.13);

  &:hover {
    &.clickable {
      cursor: pointer;
      box-shadow: 0 0 var(--endpoint-shadow-size) rgba(23, 49, 65, 0.3);
      transition: all ease-out 0.25s;
    }
  }

  &:active {
    cursor: grabbing;
  }
}

.content {
  background-color: rgba(255, 255, 255, 0.9);
}
