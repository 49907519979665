.wrapper {
  .title {
    font-weight: bold;
    font-size: 16px;
    line-height: 12px;
    color: #000;
    margin-bottom: 11px;
  }

  .list {
    .item {
      margin-bottom: 7px;
      border-bottom: 2px dotted #d1deea;
      list-style: none;

      .content {
        position: relative;
        top: 3px;
        display: flex;
        min-width: 100%;
        align-items: flex-end;
        justify-content: space-between;
        font-size: 14px;
        line-height: 16px;
        color: #000;
      }

      .kind {
        span {
          display: inline;
          font-weight: 500;
          background-color: #fff;
          box-decoration-break: clone;
        }
      }

      .point {
        flex: 1;
        font-weight: bold;
        text-align: right;

        .pointBg {
          display: inline-block;
          padding-left: 5px;
          background-color: #fff;
        }
      }

      &.total {
        margin-top: 20px;

        .kind {
          font-weight: bold;
          color: #365b70;
        }
      }
    }
  }
}
