.wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  background-color: #fff;

  .centerPanel {
    flex-grow: 1;
    max-width: 65%;
    height: 100%;
    padding-left: 16px;

    &.wide {
      max-width: 100%;
    }
  }

  .rightPanel {
    position: relative;
    overflow: hidden;
    flex-grow: 1;
    height: 100%;
    max-width: 35%;

    .rightPanelTabsWrapper {
      display: flex;
      justify-content: center;

      .rightPanelTabs {
        margin: 16px auto;
      }
    }

    .rightPanelContent {
      height: calc(100% - 58px);
    }
  }
}

:global {
  .gutter {
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: 50%;
    transition: all 0.25s ease-out;

    &:hover {
      background-color: #eee;
    }
  }

  .gutter.gutter-horizontal {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
    cursor: col-resize;
  }

  .gutter.gutter-vertical {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=');
    cursor: row-resize;
  }
}
