.menu {
  margin: 0;
  margin-left: -19px;
  margin-right: -19px;
  padding: 0;

  &.fill {
    padding-top: 10px;
    padding-bottom: 10px;
    width: 360px;
    border-radius: 0;
  }
}

.item {
  display: flex;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 19px;
  padding-right: 19px;
  border-radius: 0;

  .itemContent {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .itemTitle {
      margin-bottom: 2px;
      font-weight: 500;
      font-size: 13px;
      line-height: 14px;
      color: #000;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .itemExample {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #8a969f;
    }
  }

  &:global(.bp3-active) {
    .itemContent {
      .itemTitle {
        color: #fff;
      }

      .itemExample {
        color: #bbb;
      }
    }
  }
}

.icon {
  display: block;
  width: 36px;
  height: 36px;
  margin-right: 7px;
}
