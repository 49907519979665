.wrapper {
  position: absolute;
  top: 0;
  left: 50%;
  width: 320px; /* Adjusted width */
  display: flex;
  justify-content: center;
  margin-left: -160px; /* Adjusted margin to center */
  background-color: #fff;
  border-radius: 0 0 6px 6px;
  box-shadow: 0 4px 8px rgba(25, 66, 89, 0.04);
  font-size: 13px;
  color: #8e9da8;
  z-index: 30;
  transition: box-shadow 0.5s ease-in-out;
  cursor: default;

  &:hover {
    box-shadow: 0 4px 8px rgba(25, 66, 89, 0.15);
  }
}

.inner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}

.networkpolicylogo {
  position: relative;
  top: -0.5px;
  height: 23px;
  margin-right: 5px;
}



.isovalentlogo {
  width: 89px;
  height: 11px;
  margin-left: 5px;
}

.isovalentText {
  font-weight: bold;
  color: #333; 
  margin-left: 5px;
  font-size: 14px;
}



.isovalentPopup {
  border-radius: 6px;
  :global(.bp4-popover2-arrow-fill) {
    fill: rgb(27, 51, 97);
  }
  :global(.bp4-popover2-content) {
    border-radius: 6px;
    background: rgb(27, 51, 97);
    background: linear-gradient(
      180deg,
      rgba(27, 51, 97, 1) 0%,
      rgba(11, 14, 31, 1) 100%
    );
  }

  &:global(.bp4-popover2.bp4-popover2-content-sizing) {
    :global(.bp4-popover2-content) {
      padding: 17px;
      max-width: 510px;
    }
  }
}

.popoverContent {
  display: flex;
  .section {
    width: 50%;

    &:first-child {
      padding-right: 17px;
      border-right: 1px solid rgba(255, 255, 255, 0.1);
    }

    &:last-child {
      padding-left: 17px;
    }

    .isovalentWordmark {
      width: 95px;
      height: 40px;
      margin-bottom: 10px;
    }

    .buildingsIcon {
      width: 27px;
      height: 20px;
      margin-bottom: 10px;
    }

    .text {
      margin-bottom: 18px;
      color: #fff;
      font-size: 14px;
    }

    .footer {
      display: flex;
      align-items: center;
      gap: 10px;
      white-space: nowrap; 

      span {
        color: #fff;
      }

      .isovalentLink {
        color: #386aff;
        font-weight: 600;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
