.wrapper {
  width: 360px;
  max-height: calc(100vh - 60px);
  overflow: auto;
  padding: 15px 19px;
  font-size: 14px;

  :global(.bp3-input-ghost),
  :global(.bp3-tag) {
    font-size: 14px;
  }

  .description {
    display: inline-block;
    margin-bottom: 12px;
    word-wrap: break-word;
    word-break: break-word;

    &:last-child {
      margin-bottom: 0;
    }

    .title {
      font-weight: 700;
      color: #444;
      margin-bottom: 5px;
    }

    .text + .title {
      margin-top: 10px;
    }
  }

  .section {
    position: relative;
    display: block;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    &.l7 {
      margin: 0 10px;

      :global(.bp3-switch) {
        position: relative;
        top: -4px;
      }
    }

    .label {
      display: inline-block;
      position: relative;
      margin-bottom: 8px;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      color: #435d71;

      &:last-child {
        margin-bottom: 0;
      }

      &.required {
        &::after {
          position: absolute;
          top: 3px;
          right: -20px;
          content: "﹡";
          font-size: 16px;
          color: rgb(139, 61, 61);
        }
      }
    }

    .addExpressionButton {
      position: absolute;
      top: -3px;
      right: 0;
      font-weight: 700;
      font-style: italic;
    }

    .labelNote {
      display: block;
      margin-top: 3px;
      color: #444;
    }

    .namespaceSelectorAllCheckbox {
      margin-top: 5px;
      font-size: 90%;
    }
  }

  .warn {
    margin-left: -3px;
    margin-right: -3px;
    background-color: #f9ecdf;
    color: #bf7326;
    padding: 3px 7px;
    border-radius: 3px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .error {
    margin-top: 12px;
    margin-left: -3px;
    margin-right: -3px;
    background-color: #f9dfdf;
    color: #bf2626;
    padding: 3px 7px;
    border-radius: 3px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.podSelectorTextarea {
  display: block;
  width: 100%;
}
