.wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 12px;
  width: 100%;
  min-width: 100%;

  &:last-child {
    margin-bottom: 0;
  }

  .action {
    margin-left: 7px;
    margin-bottom: 3px;

    &:first-child {
      margin-left: 0;
    }
  }
}
