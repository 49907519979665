@import "~/styles/common/layout.scss";

$header-padding: 13px 15px;

.cardHeader {
  @include column();

  width: 100%;
  max-width: 100%;

  padding: $header-padding;

  .top {
    @include row();
  }

  .icon {
    @include centered();
    flex-shrink: 0;
    width: 30px;
    margin-right: 9px;

    img {
      text-align: center;
    }
  }

  .captions {
    @include column(center);
    flex-grow: 1;

    .title {
      @include ellipsis-limit();

      font-size: 14px;
      line-height: 18px;
      font-weight: bold;
      color: #303030;
    }

    .subTitle {
      @include ellipsis-limit();
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      color: #778187;
    }
  }

  .addIcon {
    // margin-top: -10px;
    // margin-right: -10px;
  }

  &.active {
    .icon {
      img {
        filter: brightness(2);
      }
    }

    .title,
    .subTitle {
      color: #fff;
    }
  }
}

.innerEndpoints {
  padding: 0 10px;

  :global(.bp3-popover-wrapper) {
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}

.endpointSelector {
  display: flex;
  flex-direction: column;
  margin: -5px 14px 14px 14px;
  padding: 8px;
  font-weight: 600;
  border: 1px solid #dbe7ef;
  border-radius: 3px;
  font-size: 12px;
  line-height: 18px;
  color: #1d5e8c;

  .endpointSelectorLine {
    margin-bottom: 3px;
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.endpointItem {
  display: flex;
  flex: 1;
  min-width: 0;
  align-items: center;
  padding: 0 7px;
  font-weight: 500;
  font-size: 13px;
  line-height: 28px;
  white-space: pre;

  .endpointItemContent {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:last-child {
    margin-bottom: 10px;
  }

  &:not(.notsupported) {
    cursor: pointer;

    &:hover {
      background-color: #efefef;
    }
  }

  border: 1px solid #e7e7e7;
  border-radius: 3px;

  &.active {
    background-color: #e5f4ff;
    border-color: #1d5e8c;
    // color: #fff;

    &:hover {
      background-color: #d4eeff;
      // color: #fff;
    }
  }

  .port,
  .l7label,
  .cidrExceptIcon {
    position: static;
    flex-shrink: 0;
    margin-left: 4px;
  }

  .cidrExceptIcon {
    color: #5c7080;
  }
}

.wrapper {
  border: 1px solid transparent;

  &.active {
    background-color: #e5f4ff;
    border-color: #1d5e8c;
    border-radius: 10px;

    &:hover {
      cursor: default;
    }

    .innerEndpoint {
      &.active {
        background-color: #114163;
        border-color: #114163;
        color: #fff;
        cursor: default;

        &:hover {
          cursor: default;
        }
      }

      &:hover {
        background-color: #114163;
        border-color: #114163;
        color: #fff;
        cursor: pointer;
      }
    }
  }
}

.connectors {
  @include row(space-between, center);

  margin-top: 10px;

  .left,
  .right {
    @include row(default, center);
    font-size: 8px;
    line-height: 120%;
    height: 24px;

    color: #d94c4c;
    font-weight: bold;

    &:hover {
      color: #d94c4c;
    }

    &.allow {
      color: #288f0e;

      &:hover {
        color: #288f0e;
      }
    }

    svg {
      fill: currentColor;
    }
  }

  .left {
    margin-left: -10px;
  }

  .right {
    margin-right: -10px;
  }
}
