@import '~/styles/common/layout.scss';

.cimulator {
  @include column();
  flex-grow: 1;

  position: relative;
  overflow: hidden;

  width: 100%;
  height: 100%;

  .networkpolicyLogo {
    position: absolute;
    top: 10px;
    left: 15px;
    width: 159px;
    z-index: 0;
  }

  .loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: var(--loading-overlay-z-index);
    display: flex;
    align-items: center;
    justify-content: center;
    background: url('/static/assets/images/cilium-pattern.png') repeat #f0f3f4;
    background-position: 121px 25px;
    background-size: 242px auto;

    &.transparent {
      background-color: rgba(255, 255, 255, 0.8);

      .loaderText {
        background-color: #fff;
      }
    }

    .loaderText {
      padding: 2px 5px;
      border-radius: 3px;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .map {
    box-shadow: inset 0 0 23px rgba(8, 44, 65, 0.12);
    background: url('/static/assets/images/cilium-pattern.png') repeat #f0f3f4;
    background-position: 121px 25px;
    background-size: 242px auto;
    flex-grow: 1;
  }
}

.alertsPortal {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  z-index: var(--alerts-portal-z-index);
}

.preload {
  position: absolute;
  top: -100000px;
  left: -100000px;
  width: 0;
  height: 0;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

.onboarding,
.downloadDialog,
.shareDialog {
  .title {
    font-weight: bold;
    text-align: center;
    color: #000;
    margin-bottom: 20px;
  }

  h1.title {
    font-size: 22px;
    line-height: 22px;
  }

  h2.title {
    font-size: 18px;
    line-height: 18px;
  }

  .code {
    padding: 17px 14px;
    margin: 10px 0;
    border: 1px solid #dbe7ef;
    box-sizing: border-box;
    border-radius: 6px;
    font-size: 14px;
    font-family: 'Fira Code', monospace;
  }

  .tryItTitle {
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 18px;
    margin: 22px 0 11px 0;
    text-align: left;
  }

  .requiresNote {
    font-size: 10px;
  }

  .locker {
    margin: 0 auto;
    display: block;
    width: 97.39px;
    height: 84.36px;
    margin-bottom: 22px;
  }

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 22px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
