.action {
  text-decoration: underline;
  cursor: pointer;

  &.allow {
    color: #54a82d;

    &:hover {
      color: #3e851e;
    }
  }

  &.deny {
    color: #e26d66;

    &:hover {
      color: #c95650;
    }
  }
}
