.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 22px;

  h1 {
    margin-bottom: 18px;
    font-size: 18px;
    line-height: 18px;
    font-weight: 700;
    color: #000;
  }

  h2 {
    margin-top: 0;
    margin-bottom: 9px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #000;
  }

  h3 {
    margin-top: 0;
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #365b70;
  }

  .description {
    font-size: 14px;
    line-height: 20px;
    color: #778187;
    font-weight: 500;

    img {
      width: 100%;
    }

    p,
    blockquote,
    ul,
    li,
    pre {
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    blockquote {
      background-color: rgb(240, 240, 208);
      padding: 10px;
    }

    ul,
    ol {
      padding: 0 0 0 2em;
    }

    li p:last-child {
      margin: 0;
    }

    pre,
    code {
      font-family: "Fira Code", monospace;
    }

    pre {
      background-color: #efefef;
      padding: 10px;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .actions {
    border-top: 1px solid #e4ecf1;
    border-bottom: 1px solid #e4ecf1;
    margin-top: 12px;

    &:first-child {
      margin-top: 0;
    }

    .actionWrapper {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 9px 0;
      margin-bottom: 5px;
      border-bottom: 1px solid #e4ecf1;

      &:last-child {
        margin-bottom: 0;
        border-bottom: none;
      }
    }

    .actionElement {
      display: flex;
      width: 100%;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #000;

      &:last-child {
        margin-bottom: 0;
      }

      .actionElementContent {
        display: flex;
        width: 100%;
        align-items: center;
        margin-right: 11px;

        &:last-child {
          margin-right: 0;
        }
      }

      .buttonAction {
        display: flex;
        flex: 1;
        min-width: 100%;

        .buttonActionText {
          display: flex;
          width: 100%;
          align-items: center;
          flex: 1;
        }

        .buttonActionButton {
          display: block;
          flex-shrink: 0;
          align-self: flex-start;
        }
      }
    }
  }
}
