.wrapper {
  height: 100%;
  overflow: auto;
  padding: 5px 17px 22px 10px;

  &.promo,
  &.help {
    min-width: 340px;
  }

  &.promo {
    .image {
      width: 86.21px;
      height: 61.21px;
    }

    .title {
      margin-top: 12px;
      font-weight: bold;
      font-size: 16px;
      line-height: 18px;
      color: #182026;
    }

    .description {
      margin-top: 7px;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #6c7986;
    }

    .actions {
      display: flex;
      margin: 18px 0;
    }
  }

  &.help {
    .text {
      margin: 0 7px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #000;
    }

    .hubbleObserveCmd {
      display: inline-flex;
      position: relative;
      margin-bottom: 7px;
      padding: 3px 17px 3px 7px;
      background: #edf4fa;
      border-radius: 4px;
      font-size: 12px;
      line-height: 18px;
      font-weight: 500;
      color: #4c6b82;
      font-family: 'Fira Code', monospace;
      text-decoration: underline;

      u {
        margin-right: 5px;
      }

      .copyIcon {
        position: absolute;
        top: 5px;
        right: 7px;
        display: inline;
      }
    }

    .actions {
      display: flex;
      margin-bottom: 18px;
    }
  }

  &.flows {
    height: 100%;

    .header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      flex-shrink: 0;
      height: 40px;
      padding: 0 0 8px 8px;

      .left,
      .right {
        display: flex;
        align-items: center;
      }

      .right {
        justify-content: flex-end;
      }

      .title {
        margin-right: 20px;
        font-size: 14px;
        line-height: 18px;
        color: #8a969f;
      }

      .close {
        float: right;
      }

      .actions {
        display: flex;
        align-items: center;
      }
    }

    .table {
      width: 100%;
      height: calc(100% - 28px);
      flex: 1;
    }
  }

  .uploadButton {
    margin-right: 10px;
  }
}
