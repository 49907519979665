.wrapper {
  $margin-bottom: 10px;
  $header-height: 28px;
  $unsupported-warning-height: 26px;

  height: calc(100% - #{$margin-bottom});
  margin-bottom: $margin-bottom;
  margin-left: 10px;
  margin-right: 7px;
  padding: 14px 0;
  overflow: hidden;
  background: #fdfdfd;
  border: 1px solid #d1deea;
  border-radius: 10px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $header-height;
    padding: 0 14px;

    .left,
    .center,
    .right {
      display: flex;
      align-items: center;
      flex-shrink: 0;
    }

    .left {
      .leftActions {
        margin-right: 10px;

        > * {
          margin-right: 3px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .center {
      flex: 1;
      justify-content: flex-start;
    }

    .right {
      justify-content: flex-end;

      .policyRating {
        display: flex;
        align-items: center;
        margin-right: 15px;
        font-weight: 500;
        font-size: 14px;
        line-height: 12px;
        cursor: help;

        .policyRatingTitle {
          margin-right: 7px;
          color: #6c7986;
        }
      }

      @media only screen and (max-width: 1440px) {
        .policyRatingTitle {
          display: none;
        }
      }
    }

    .specsSelector {
      margin-right: 10px;
    }
  }

  .unsupportedWarning {
    display: block;
    max-width: calc(100% - 28px);
    height: $unsupported-warning-height;
    text-align: left;
    margin: 14px 14px 0 14px;
    padding: 0 10px;
    font-size: 12px;
    line-height: $unsupported-warning-height;
    font-weight: 600;
    color: #000;
    background-color: #f5eee7;
    border-radius: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
    cursor: help;
  }

  .editor {
    margin-top: 14px;
    height: calc(100% - #{$header-height} + 3px);

    &.unsupportedWarningOpened {
      height: calc(
        100% - #{$header-height} + 3px - 14px - #{$unsupported-warning-height}
      );
    }
  }

  .uploadYamlButton,
  .newYamlButton,
  .downloadYamlButton,
  .shareYamlButton {
    flex-shrink: 0;
    white-space: pre;
  }

  .downloadYamlButton {
    margin-right: 10px;
  }
}
